import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
const routes = [
  {
    path: '/',
    name: 'pwrubata',
    component: () => import(/* webpackChunckName: "MainLayout" */'@/views/HomeView.vue'),
    children: [
      {
        path: "/:lang",
        name: 'home',
        component: () => import(/* webpackChunckName: "HomePage" */ '@/pages/LeftHome.vue'),
        props: ( route ) => {
          const lang = route.path.lang
          return lang? lang:'it'
        }
      }
    ]
  },
  {
    path: '/landing-mail.php',
    name: 'landing-mail',
    component: HomeView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
