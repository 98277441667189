
export const getEmail = state => () => {
    return state.email
}

export const getPubHome = state => () => {
    return state.pubblicityHome
}

export const getPubResult = state => () => {
    return state.pubblicityResult
}

export const getZone = state => () => {
    return state.zone
}