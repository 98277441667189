
export const setEmail = (state, email) => {
    state.email = email
}

export const decrease = (state) => {
    state.limit--;
}

export const pubblicityHomeChange = (state) => {
    state.pubblicityHome = true
}
export const pubblicityResultChange = (state) => {
    state.pubblicityResult = true
}
export const setHome = (state) => {
    state.zone = 'home'
}
export const setResult = (state) => {
    state.zone = 'result'
}


export const init = (state) => {
    state.limit = 5;
    state.email = "";
    state.pubblicityHome = false;
    state.pubblicityResult = false;
    state.zone = 'home'
}