import { stringifyQuery } from 'vue-router'
import { createStore } from 'vuex'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'
import * as state from './state'

export default createStore({
  state,
  actions,
  getters,
  mutations
})
