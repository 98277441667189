export const  updateEmail = ({ commit }, email) => {
    commit('setEmail', email);
}

export const updateLimit = ({ commit }) => {
    commit('decrease');
}

export const init = ({commit}) => {
    commit('init');
}

export const setHome = ({commit}) => {
    commit('setHome')
}

export const setResult = ({commit}) => {
    commit('setResult')
}

export const pubblicityHomeChange = ({commit}) => {
    commit('pubblicityHomeChange')
}

export const pubblicityResultChange = ({commit}) => {
    commit('pubblicityResultChange')
}